e4.ecom.productlist = {
    jProductList: jQuery( '.js-e-productlist' ),
    init: function () {
        if ( e4.ecom.productlist.jProductList.length === 1 ) {
            e4.util.require.js( e4.settings.require.handlebars, function () {
                e4.handlebars.registerHelpers( 'compare' );
                e4.handlebars.registerTemplates( '#js-e-productlist-filters-handlebars-template #js-e-productlist-products-handlebars-template #js-e-productlist-filters-modal-template' );
                e4.ecom.productlist.history.init();
                e4.ecom.productlist.filters.init();
                e4.ecom.productlist.modal.init();
                e4.ecom.productlist.page.init();
            } );
        }
    },
    filters: {
        init: function () {
            e4.ecom.productlist.filters.bindEvents();
            e4.ecom.productlist.filters.innerPeek.init();
            e4.ecom.productlist.filters.range.init();
            e4.ecom.productlist.filters.search.init();

        },
        bindEvents: function () {
            e4.ecom.productlist.jProductList.on( 'change', '.js-e-productlist-filter', function () {
                e4.ecom.productlist.filters.apply( this );
            } );
            e4.ecom.productlist.jProductList.on( 'click', '.js-e-productlist-filter-remove', function ( event ) {
                event.preventDefault();
                e4.ecom.productlist.filters.removeFilter( this );
            } );
            jQuery( document ).on( 'click', '.js-e-productlist-filter-clear-all', function ( event ) {
                event.preventDefault();
                e4.ecom.productlist.filters.resetAll();

            } );
            e4.ecom.productlist.jProductList.on( 'change', '.js-e-productlist-filter-reset-all', function () {
                e4.ecom.productlist.filters.resetGroup( this );
            } );
            e4.ecom.productlist.jProductList.on( 'click', '.js-e-productlist-filters-collapse-peek-toggle', function ( event ) {
                event.preventDefault();
                e4.ecom.productlist.filters.peek( this );
            } );

            e4.ecom.productlist.jProductList.on( 'keypress', '.js-e-productlist-form', function ( event ) {
                if ( event.which === 13 ) {
                    e4.ecom.productlist.data.post( e4.ecom.productlist.jProductList.find( '.js-e-productlist-form' ).serialize(), jQuery( '.js-e-productlist' ).offset().top );
                }
            } );

            jQuery( document ).on( 'click', '.js-e-productlist-filter-range-clear', function ( event ) {
                event.preventDefault();
                var jGroupToClear = jQuery( '#' + jQuery( this ).data( 'group-id' ) );
                jGroupToClear.find( '.js-e-productlist-filter-range' ).val( '' );
                e4.ecom.productlist.data.post( e4.ecom.productlist.jProductList.find( '.js-e-productlist-form' ).serialize(), jQuery( '.js-e-productlist' ).offset().top );
            } );
        },
        refresh: function () {
            e4.ecom.productlist.filters.innerPeek.setInitialCollapse();
            e4.ecom.productlist.filters.range.validate();
        },
        peek: function ( domElm ) {
            var jPeekable = jQuery( domElm ).closest( '.js-e-productlist-filters-collapse-peek' );
            if ( jPeekable.is( '.open' ) ) {
                if ( jPeekable.data( 'collapsed' ) ) {
                    jPeekable.css( 'max-height', jPeekable.data( 'collapsed' ) );
                }
                else {
                    jPeekable.removeAttr( 'style' );
                }

            }
            else {
                if ( jPeekable.data( 'expanded' ) ) {
                    jPeekable.css( 'max-height', jPeekable.data( 'expanded' ) );
                }
            }
            jQuery( domElm ).closest( '.js-e-productlist-filters-collapse-peek' ).toggleClass( 'open' );
        },
        apply: function ( domElm ) {
            var jThis = jQuery( domElm );
            jThis.closest( '.js-e-productlist-filters-items' ).find( '.js-e-productlist-filter-reset-all' ).prop( 'checked', false );
            if ( jThis.is( ':checked' ) === false ) {
                var checkedLength = jThis.closest( '.js-e-productlist-filters-items' ).find( '.js-e-productlist-filter' ).filter( ':checked' ).length;
                if ( checkedLength === 0 ) {
                    jThis.closest( '.js-e-productlist-filters-items' ).find( '.js-e-productlist-filter-reset-all' ).prop( 'checked', true );
                }
            }

            jQuery( 'input[name="PageNum"]' ).val( 1 );
            e4.ecom.productlist.data.post( e4.ecom.productlist.jProductList.find( '.js-e-productlist-form' ).serialize(), jQuery( '.js-e-productlist' ).offset().top );
        },
        removeFilter: function ( domElm ) {
            var jValue = jQuery( domElm ).data( 'value' );
            e4.ecom.productlist.jProductList.find( '.js-e-productlist-filter[value="' + jValue + '"]' ).prop( 'checked', false );
            e4.ecom.productlist.data.post( e4.ecom.productlist.jProductList.find( '.js-e-productlist-form' ).serialize(), jQuery( '.js-e-productlist' ).offset().top );
        },
        resetGroup: function ( domElm ) {
            jQuery( domElm ).closest( '.js-e-productlist-filters-items' ).find( '.js-e-productlist-filter' ).prop( 'checked', false );
            e4.ecom.productlist.data.post( e4.ecom.productlist.jProductList.find( '.js-e-productlist-form' ).serialize(), jQuery( '.js-e-productlist' ).offset().top );
        },
        resetAll: function () {
            jQuery( 'input[name="PageNum"]' ).val( 1 );
            e4.ecom.productlist.jProductList.find( '.js-e-productlist-filter' ).prop( 'checked', false );
            e4.ecom.productlist.jProductList.find( '.js-e-search-input' ).val( '' );
            e4.ecom.productlist.jProductList.find( '.js-e-productlist-filter-range' ).val( '' );
            e4.ecom.productlist.data.post( e4.ecom.productlist.jProductList.find( '.js-e-productlist-form' ).serialize(), 0 );
        },
        innerPeek: {
            init: function () {
                e4.ecom.productlist.filters.innerPeek.bindEvents();
            },
            bindEvents: function () {
                e4.ecom.productlist.jProductList.on( 'shown.bs.collapse', '.js-e-productlist-filters-collapse-peek', function () {
                    e4.ecom.productlist.filters.innerPeek.setHeight( this );
                } );
            },
            setInitialCollapse: function () {
                var firstOpenCollapse = e4.ecom.productlist.jProductList.find( '.js-e-productlist-filters-collapse-peek' ).first();
                e4.ecom.productlist.filters.innerPeek.setHeight( firstOpenCollapse );
            },
            setHeight: function ( domElm ) {
                var initialCollapseHeight = parseInt( jQuery( domElm ).css( 'max-height' ) ),
                    intVisibleOptionCount = parseInt( jQuery( '.js-e-visible-options-count' ).val() ) + e4.settings.ecom.filters.peek.addedHeight,
                    intFacetGroupCount = jQuery( '.js-e-productlist-filters' ).find( '.js-e-productlist-filters-items' ).length,
                    intFacetItemsCount = jQuery( domElm ).find( '.js-e-productlist-filters-item' ).length,
                    jLastCheckedItem = jQuery( domElm ).find( '.js-e-productlist-filters-item-checked' ).last(),
                    intExpandedHeight = jQuery( domElm ).find( '.js-e-productlist-filters-item' ).first().height() * ( intFacetItemsCount + e4.settings.ecom.filters.peek.addedHeight );
                if ( jLastCheckedItem.index() > e4.settings.ecom.filters.peek.offset ) {
                    if ( jLastCheckedItem.nextAll( '.js-e-productlist-filters-item' ).length > intVisibleOptionCount ) {
                        var intCollapsedHeight = initialCollapseHeight + ( jQuery( domElm ).find( '.js-e-productlist-filters-item' ).first().height() * ( jLastCheckedItem.index() + e4.settings.ecom.filters.peek.addedHeight ) );
                        jQuery( domElm ).css( 'max-height', intCollapsedHeight ).attr( 'data-collapsed', intCollapsedHeight );
                    }
                    else {
                        jQuery( domElm ).addClass( 'open' );
                    }
                }
                else {
                    var intCollapsedHeight = intVisibleOptionCount * jQuery( domElm ).find( '.js-e-productlist-filters-item' ).first().height();
                    jQuery( domElm ).css( 'max-height', intCollapsedHeight ).attr( 'data-collapsed', intCollapsedHeight );
                }

                jQuery( domElm ).attr( 'data-expanded', intExpandedHeight );
            }
        },
        range: {
            init: function () {
                e4.ecom.productlist.filters.range.bindEvents();
            },
            bindEvents: function () {
                e4.ecom.productlist.jProductList.on( 'click', '.js-e-productlist-filter-apply-range', function () {
                    var jContext = jQuery( this ).closest( '.js-e-productlist-filters-collapse' );
                    if ( jContext.find( '.js-e-productlist-filter-range-max' ).valid() ) {
                        jQuery( 'input[name="PageNum"]' ).val( 1 );
                        e4.ecom.productlist.data.post( e4.ecom.productlist.jProductList.find( '.js-e-productlist-form' ).serialize(), jQuery( '.js-e-productlist' ).offset().top );
                    }
                } );
            },
            validate: function () {
                jQuery.validator.addMethod( 'rangeValid', function ( value, element, compareInput ) {
                    return parseInt( value ) > parseInt( jQuery( compareInput ).val() );

                } );

                e4.ecom.productlist.jProductList.find( '.js-e-productlist-form' ).validate( {
                    rules: {
                        PriceRangeMin: {
                            number: true

                        },
                        PriceRangeMax: {
                            number: true,
                            rangeValid: '.js-e-productlist-filter-range-min'
                        }
                    }
                } );
            }
        },
        search: {
            init: function () {
                e4.ecom.productlist.filters.search.bindEvents();
            },
            bindEvents: function () {
                e4.ecom.productlist.jProductList.on( 'click', '.js-e-productlist-filters-search-submit', function () {
                    e4.ecom.productlist.data.post( e4.ecom.productlist.jProductList.find( '.js-e-productlist-form' ).serialize(), jQuery( '.js-e-productlist' ).offset().top );
                } );
                e4.ecom.productlist.jProductList.on( 'click', '.js-e-search-clear', function () {
                    e4.search.clearInput( this );
                    e4.ecom.productlist.data.post( e4.ecom.productlist.jProductList.find( '.js-e-productlist-form' ).serialize(), jQuery( '.js-e-productlist' ).offset().top );
                } );
                e4.ecom.productlist.jProductList.on( 'keyup input', '.js-e-search-input', function ( event ) {
                    e4.search.toggleInputGroupState( this, event );
                } );
                e4.ecom.productlist.jProductList.on( 'click', '.js-e-productlist-filter-search-clear', function ( event ) {
                    event.preventDefault();
                    e4.ecom.productlist.jProductList.find( '.js-e-search-input' ).val( '' );
                    e4.ecom.productlist.data.post( e4.ecom.productlist.jProductList.find( '.js-e-productlist-form' ).serialize(), jQuery( '.js-e-productlist' ).offset().top );
                } );
            }
        }
    },
    modal: {
        init: function () {
            e4.ecom.productlist.modal.bindEvents();
        },
        bindEvents: function () {
            jQuery( '.js-e-productlist-filters-modal' ).on( 'click', '.js-e-productlist-filter-label-mobile', function () {
                var jCheckbox = jQuery( this ).prev( 'input[type="checkbox"]' );
                jQuery( this ).prev( 'input[type="checkbox"]' ).prop( 'checked', jCheckbox.prop( 'checked' ) === false );
            } ).on( 'input keyup', '.js-e-productlist-filter-range', function () {
                var jName = jQuery( this ).attr( 'name' ),
                    jValue = jQuery( this ).val();
                jQuery( '.js-e-productlist-form' ).find( 'input[name="' + jName + '"]' ).val( jValue );
            } ).on( 'click', '.js-e-productlist-filter-apply-range', function () {
                e4.ecom.productlist.data.post( e4.ecom.productlist.jProductList.find( '.js-e-productlist-form' ).serialize(), 0 );
            } ).on( 'show.bs.modal', function () {
                e4.util.hash.set( 'isModalFilter', 'true' );
            } ).on( 'hide.bs.modal', function () {
                jQuery( '.js-e-product-grid-loading-overlay' ).addClass( 'is-loading' );
                e4.util.hash.remove( 'isModalFilter' );
                setTimeout( function () {
                    jQuery( '.js-e-product-grid-loading-overlay' ).removeClass( 'is-loading' );
                }, e4.settings.ecom.filters.delay );
            } );
        }
    },
    page: {
        init: function () {
            e4.ecom.productlist.page.bindEvents();
            e4.ecom.productlist.page.setScrollDepth();
        },
        bindEvents: function () {
            e4.ecom.productlist.jProductList.on( 'click', '.js-e-productlist-set-pagesize', function ( event ) {
                event.preventDefault();
                var intPagesize = jQuery( this ).data( 'page-size' );
                e4.ecom.productlist.page.setPagesize( intPagesize );
            } );
            e4.ecom.productlist.jProductList.on( 'click', '.js-e-productlist-set-pagenum', function ( event ) {
                event.preventDefault();
                e4.ecom.productlist.page.setPagenum( this );

            } );
            e4.ecom.productlist.jProductList.on( 'change', '.js-e-productlist-sort', function () {
                e4.ecom.productlist.page.sorting( this );
            } );
        },
        setPagesize: function ( intPagesize ) {
            e4.ecom.productlist.jProductList.find( 'input[name="PageSize"]' ).val( intPagesize );
            e4.ecom.productlist.jProductList.find( 'input[name="PageNum"]' ).val( 1 );
            e4.ecom.productlist.data.post( e4.ecom.productlist.jProductList.find( '.js-e-productlist-form' ).serialize(), jQuery( '.js-e-productlist' ).offset().top );
        },
        setPagenum: function ( domElm ) {
            var intPagenum = jQuery( domElm ).data( 'page-num' );
            e4.ecom.productlist.jProductList.find( 'input[name="PageNum"]' ).val( intPagenum );
            e4.ecom.productlist.data.post( e4.ecom.productlist.jProductList.find( '.js-e-productlist-form' ).serialize(), jQuery( '.js-e-productlist' ).offset().top );
        },
        sorting: function ( domElm ) {
            var arrSelectSortValue = jQuery( domElm ).val().split( ' ' );
            if ( arrSelectSortValue.length === 2 ) {
                e4.ecom.productlist.jProductList.find( 'input[name="Sort"]' ).val( arrSelectSortValue[ 0 ] + ' ' + arrSelectSortValue[ 1 ] );
                e4.ecom.productlist.jProductList.find( 'input[name="SortBy"]' ).val( arrSelectSortValue[ 0 ] );
                e4.ecom.productlist.jProductList.find( 'input[name="SortOrder"]' ).val( arrSelectSortValue[ 1 ] );
            }
            else {
                e4.ecom.productlist.jProductList.find( 'input[name="SortBy"], input[name="SortOrder"]' ).val( '' );
            }
            e4.ecom.productlist.data.post( e4.ecom.productlist.jProductList.find( '.js-e-productlist-form' ).serialize(), jQuery( '.js-e-productlist' ).offset().top );
        },
        setScrollDepth: function () {
            e4.ecom.productlist.jProductList.on( 'click', '.js-e-grid-item a', function () {
                e4.ecom.productlist.history.pushState();
            } );
        },
    },
    history: {
        params: null,
        init: function () {
            if ( history.state !== null && history.state.params !== '' ) {
                e4.ecom.productlist.data.post( history.state.params, history.state.scrollTop );
            }
            else {
                e4.ecom.productlist.data.post( e4.ecom.productlist.jProductList.find( '.js-e-productlist-form' ).serialize(), 0 );
            }
            e4.ecom.productlist.history.popState();
        },
        pushState: function () {
            var strUrlParam = e4.ecom.productlist.history.params.indexOf( '?' ) > -1 ? e4.ecom.productlist.history.params : '?' + e4.ecom.productlist.history.params, // Kan det virkelig ske?
                stateObj = {
                    params: e4.ecom.productlist.history.params,
                    scrollTop: jQuery( window ).scrollTop()
                };
            history.pushState( stateObj, null, location.pathname + strUrlParam );
        },
        popState: function () {
            jQuery( window ).on( 'popstate', function ( event ) {
                if ( event.originalEvent.state !== null ) {
                    e4.ecom.productlist.data.post( event.originalEvent.state.params, event.originalEvent.state.scrollTop );
                }
            } );
        }
    },
    data: {
        post: function ( strUrlParam, intScrollTop ) {
            jQuery.ajax( {
                    url: e4.settings.ecom.filters.url,
                    type: 'POST',
                    beforeSend: function () {
                        jQuery( '.js-e-product-grid-loading-overlay' ).addClass( 'is-loading' );
                    },
                    data: strUrlParam,
                } )
                .done( function ( data ) {
                    //console.dir( data );
                    e4.ecom.productlist.history.params = strUrlParam;
                    e4.ecom.productlist.data.handler( data, intScrollTop );
                } )
                .fail( function ( error ) {
                    console.error( error );
                } )
                .always( function () {
                    setTimeout( function () {
                        jQuery( '.js-e-product-grid-loading-overlay' ).removeClass( 'is-loading' );
                        jQuery( '.js-e-productlist-filters-preloader-overlay' ).remove();
                    }, e4.settings.ecom.filters.delay );

                } );

        },
        handler: function ( data, intScrollTop ) {
            var selectedOptionsCount = 0;
            for ( var i = 0; i < data.facets.length; i++ ) {
                selectedOptionsCount += data.facets[ i ].selectedOptions.length;
            }
            if ( data.page.q !== null && data.page.q !== '' ) {
                selectedOptionsCount++;
            }
            if ( ( data.page.minPrice.clean !== null && data.page.minPrice.clean !== '' ) && ( data.page.maxPrice.clean !== null && data.page.maxPrice.clean !== '' ) ) {
                selectedOptionsCount++;
            }
            data.page.filtersAppliedCount = selectedOptionsCount;
            if ( selectedOptionsCount > 0 ) {
                data.page.hasNoInteraction = false;
            }
            if ( e4.util.hash.get( 'isModalFilter' ) === 'true' ) {
                data.page.isModalFilter = true;
            }
            e4.ecom.productlist.data.build( data, intScrollTop );
        },
        build: function ( data, intScrollTop ) {
            e4.ecom.productlist.history.pushState();
            if ( jQuery( '#js-e-productlist-filters' ).length === 1 ) {
                jQuery( '#js-e-productlist-filters' ).html( e4.handlebars.tmpl.productlistFiltersHandlebarsTemplate( data ) );
                jQuery( '#js-e-productlist-filters-modal' ).html( e4.handlebars.tmpl.productlistFiltersModalTemplate( data ) );
                e4.ecom.productlist.filters.refresh();
                if ( data.page.isModalFilter === true ) {
                    jQuery( '.js-e-productlist-filters-modal' ).addClass( 'in' ).modal( 'show' );
                }
            }
            jQuery( '#js-e-productlist-products' ).html( e4.handlebars.tmpl.productlistProductsHandlebarsTemplate( data ) );
            jQuery( window ).scrollTop( intScrollTop );

        }
    }
};

